import React, { Suspense } from 'react';

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Homepage = React.lazy(() => import('./containers/Homepage/Homepage'));
const DetailsPage = React.lazy(() => import('./containers/DetailsPage/DetailsPage'));
const Shooting = React.lazy(() => import('./containers/Shooting/Shooting'));
const Diplomas = React.lazy(() => import('./containers/Diplomas/Diplomas'));
const Albums = React.lazy(() => import('./containers/Albums/Albums'));
const Invitations = React.lazy(() => import('./containers/Invitations/Invitations'));


function App() {
  return (
    <div className="App">

      <Router>
        <Suspense fallback={<div>Loading Homepage...</div>}>

          <Routes>

            <Route exact path="/" element={<Homepage />} />
            <Route exact path="/details/:id" element={<DetailsPage />} />
            <Route exact path="/shooting/:id" element={<Shooting />} />
            <Route exact path="/diplomas" element={<Diplomas />} />
            <Route exact path="/albums" element={<Albums />} />
            <Route exact path="/invitations" element={<Invitations />} />

          </Routes>
        </Suspense>

      </Router>
      <ToastContainer autoClose={1000}   position="top-center" />

    </div>
  );
}





export default App;
